<template>
    <div class="footer content-grid">
        <Copyright class="footer__copy"/>
        <div class="footer__right">
            <Address class="footer__address"/>
            <Links/>
        </div>
        
    </div>
</template>

<script>
import Address from './footer/Address.vue'
import Copyright from './footer/Copyright.vue'
import Links from './footer/Links.vue'

export default {
    components: {
        Copyright,
        Address,
        Links
    }
}
</script>


<style lang="scss">
.footer {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    height: 422px;
    width: 100%;
    background: #000;
    padding: 133px 0 99px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // &__copy {
    //     margin-right: 509px;
    // }
    &__right {
        display: flex;
        align-items: flex-end;
    }
    &__item {
        margin-bottom: 10px !important;
        display: block;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: #d8d8d8;
        &.icons {
            margin-bottom: 50px !important;
        }
    }
    &__address {
        margin-right: 177px;
    }
    @media(max-width: 1250px) {
        // &__copy {
        //     margin-right: 309px;
        // } 
        &__address {
            margin-right: 106px;
        }
    }
    @media(max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        height: auto;
        padding-top: 102px;
        padding-bottom: 102px;
        &__item {
            font-size: 12px;
            line-height: 2;
            margin-bottom: 0 !important;
            color: #c4c4c4;
        }
        &__right {
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
        }
        &__address {
            margin: 50px 0;
        } 
    }
}
</style>