<template>
    <div style="width: 100%;">
        <Social type="footer" class="footer__item footer__social"/>
        <Navigation type="mobile" />
    </div>
</template>

<script>
import Social from '@/components/elements/Social'
import Navigation from '@/components/layout/header/Navigation'

export default {
    components: {
        Social,
        Navigation
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    .nav {
        flex-direction: column;
        align-items: flex-start;
        &__item {
            margin-right: 0;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: #c4c4c4;
        }
    }
    @media(max-width: 768px) {
        .footer__social {
            margin-bottom: 68px;
            display: flex;
            justify-content: center;
            .icons__item {
                margin-right: 21px;
                img {
                    height: 52px;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
            
        }
        .nav {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 370px;
            margin: 0 auto;
            &__item {
                line-height: 2;
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }
}
</style>
