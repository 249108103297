import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
    en: {
        header: {
            home: 'home',
            projects: 'projects',
            about_us: 'about us',
        },
        footer: {
            address: 'Kyiv, Lviv Square 1-5',
            house: `Artist's house`
        },
        main: {
            title: 'Theatre in the city',
            desc_second_screen: 'Miskyi theater was founded in Kyiv in 2021.<br> With the hope that our cities will not become parking lots.',
            link_to_project: 'works of participants of the course<br class="mobile"> on creation of audio walks',
        },
        project: {
            title: `Works of participants of the course on creation of audio walks `,
            desc: 'Learn more by link:',
            btn: 'Learn more',
            p_1: `
            In November 2021, the Miskyi theatre conducted a course on creating audio walks in the urban environment. Participants learned the basics of sound design, urban practices and creating routes in the districts of Kyiv.
            `,
            p_2: 'The curator of the course is Dima Levitsky',
            p_3: `
                Course lecturers:<br> culturologist Maria Borysova,<br> musician Jonny Fox (Sheffield, UK),<br> sound director Vasyl Yavtushenko (4Ears Sound Production)
                Лектори курсу:<br>
                культурологиня Марія Борисова,<br>
                музикант Jonny Fox (Sheffield, UK),<br>
                звукорежисер Василь Явтушенко (4Ears Sound Production)<br>
            `,
            p_4: 'The result of the course were works: ',
            works: {
                w_1: 'Dmytro Tiazhlov (work title, link, audio file)',
                w_2: 'Svitlana Dovhan (job title, link, audio file)',
                w_3: 'Maryana Klochko (title of work, link, audio file)',
                w_4: 'Pinchuk Dzvinka (job title, link, audio file)',
                w_5: 'Ihor Babayev (title, link, audio file)',
                w_6: 'Tetyana Pavliuk (work title, link, audio file)',
                w_7: 'Konopliana Julia (title of work, link, audio file)',
                w_8: 'Agadjanova Anaid (work title, link, audio file)',
            },
            friends: 'The course is supported by  the European Union under the "House of Europe" programme. ',

        }
    },
    ua: {
        header: {
            home: 'головна',
            projects: 'проєкти',
            about_us: 'про нас'
        },
        footer: {
            address: 'Київ, Львівська площа 1-5',
            house: `Будинок Художника`
        },
        main: {
            title: 'ТЕАТР У МІСТІ',
            desc_second_screen: `Міський театр засновано у Києві у 2021 році.<br>
            З надією, що наші міста не стануть парковками.`,
            link_to_project: 'pоботи учасниць/ків курсу<br class="mobile"> зі створення аудіопрогулянок'
        },
        project: {
            title: `Роботи учасниць/ків курсу<br>
            зі створення аудіопрогулянок`,
            desc: 'дізнатись більше можна за посиланням:',
            btn: 'Дізнатись більше',
            p_1: `
            У листопаді 2021 року Міський театр провів курс зі створення аудіопрогулянок у міському середовищу. Учасниці та учасники навчались основам звукорежисури, урбаністичним практикам та створенню маршрутів у районах Києва. 
            `,
            p_2: 'Куратор курсу - Діма Левицький',
            p_3: `
                Лектори курсу:<br>
                культурологиня Марія Борисова,<br>
                музикант Jonny Fox (Sheffield, UK),<br>
                звукорежисер Василь Явтушенко (4Ears Sound Production)<br>
            `,
            p_4: 'Результатом курсу стали роботи:  ',
            works: {
                w_1: 'Дмитро Тяжлов, Назва роботи',
                w_2: 'Світлана Довгань, Назва роботи',
                w_3: 'Мар’яна Клочко, Назва роботи',
                w_4: 'Дзвінка Пінчук, Назва роботи',
                w_5: 'Ігор Бабаєв, Назва роботи',
                w_6: 'Тетяна Павлюк, Назва роботи',
                w_7: 'Юлія Конопляна, Назва роботи',
                w_8: 'Анаід Агаджанова, Назва роботи',
            },
            friends: 'Курс створено за підтримки Європейського Союзу в рамках програми “Дім Європи". ',
        }
    },

  }
  
  export default new VueI18n({
    locale: 'uk', // set locale
    messages, // set locale messages
  })